
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
}

:root {
  font-size: 16px;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

header.desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 1em;
  background-color: #88D3E1;
  border-bottom: 5px solid #FFF;
  box-shadow: 0 0 1em #3339;
  text-align: center;
}

header.mobile {
  padding: 1em;
  text-align: center;
  border-bottom: 2px solid #88D3E1;
  overflow: hidden;
}

/* LINK & BUTTON STYLES */

a, .link {
  color: #ffffff;
  font-size: 1.5em;
  font-family: 'Bellota Text', sans-serif;
  margin: 0 .5em;
  text-align: center;
  padding: 0;
  transition: all 0.2s ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

a:hover, .link:hover {
  color: #333;
}

a:active, a.active, .link:active, .link.active {
  color: #888;
}

.form-group {
  display: flex;
  margin-bottom: 1em;
}

.form-group input, .form-group textarea {
  flex: 1;
  border: .1em solid #333;
  border-radius: .2em;
  height: 2em;
  padding: .3em .7em;
}

.form-group textarea {
  min-height: 10em;
}

.form-group label {
  font-family: 'Heebo', sans-serif;
  min-width: 8em;
  text-align: right;
  padding-right: 1em;
}

.btn {
  color: #88D3E1;
  background-color: #333;
  display: inline-block;
  border: none;
  border-radius: .15em;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  padding: .5em 1em;
  margin: .2em;
  transition: all 0.2s ease-in-out;
  font-family: 'Heebo', sans-serif;
  font-weight: bold;
  font-size: 1em;
}

.btn:hover {
  color: #88D3E1;
  background-color: #888;
  box-shadow: 0 0 .2em #CCC, 0 0 .9em #88D3E1;
}

.btn:focus {
  color: #88D3E1;
  box-shadow: 0 0 .2em #CCC, 0 0 .9em #88D3E1;
  outline: .5em auto #88D3E1;
}

/* LINK & BUTTON STYLES - END */

.hero {
  width: 100vw;
}

.page {
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

p {
  font-family: 'Bellota Text', sans-serif;
  margin: 0;
  margin-block-end: 1.5em;
}

h2 {
  font-family: 'Heebo', sans-serif;
  font-size: 2.875em;
  text-transform: uppercase;
  margin: 0;
  margin-block-end: .25em;
}

h4 {
  font-family: 'Bellota', serif;
  font-size: 1.5em;
  margin: 0;
  margin-block-end: 1em;
}

.accent {
  position: relative;
  display: inline-table;
}

.accent span:first-child {
  position: relative;
  z-index: 1;
}

.accent span:last-child {
  background-color: #88D3E1;
  position: absolute;
  height: 50%;
  width: 103%;
  right: -1.5%;
  bottom: 0;
  z-index: 0;
}

.accent-medium span:last-child {
  width: 71%;
}

section.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block {
  padding: 1em;
  width: 35em;
  max-width: 90%;
}

.block.full {
  padding: 1em 0;
  width: 100%;
  max-width: 100%;
}

.block.block-testimonial {
  background-color: #88D3E1;
  color: #FFF;
  min-height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial {
  text-align: center;
}

.testimonial-image {
  height: 8em;
  width: 8em;
  border-radius: 4em;
}

.block.block-testimonial .accent span:last-child {
  background-color: #888;
}

.text-center {
  text-align: center;
}

/* MOBILE SPECIFIC STYLES */
@media (max-width: 720px) {
  header.desktop {
    display: none;
  }

  h2 {
    font-size: 2em;
  }

  h4 {
    font-size: 1.25em;
    margin-block-end: .5em;
  }

  p {
    margin-block-end: .75em;
  }
}

/* DESKTOP SPECIFIC STYLES */
@media (min-width: 720px) {
  header.mobile {
    display: none;
  }

  .block {
    margin: 1em 0;
  }

  .navigation-social {
    float: right;
    position: absolute;
    right: 2em;
  }
}